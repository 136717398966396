body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.inp-qa {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.inp-qa > input {
  border: none;
  outline: none;
}

.inp-qa > input[type="file"] {
  display: none;
}

.inp-qa > label {
  cursor: pointer;
  padding: 1rem 5rem;
  background-color: white;
  border: 2px solid #4b4495;
  border-radius: 3px;
  color: #4b4495;
  transition: 0.5s ease;
  font-size: 16px;
  max-width: 150px;
  transition: 0.5s ease;
}

.inp-qa > label:hover {
  background: #f5f4ff;
}

.inp-qa > span {
  padding: 1rem;
}

.inp-qa > a {
  -webkit-flex: 1 1;
          flex: 1 1;
  background-color: white;
  border: 2px solid #4b4495;
  border-radius: 3px;
  padding: 1rem 5rem;
  margin-right: 1rem;
  height: 50px;
  text-align: center;
}

.inp-qa > input[type="submit"] {
  cursor: pointer;
  padding: 1rem 5rem;
  background-color: white;
  border: 2px solid #d30e36;
  border-radius: 3px;
  color: #d30e36;
  transition: 0.5s ease;
  font-size: 16px;
  max-width: 150px;
  transition: 0.5s ease;
}

.inp-qa > input[type="submit"]:hover {
  background-color: #fdd7df;
}

.qaBox {
  padding: 0 15px;
  border-radius: 3px;
}

.qaBox > p {
  color: #de6d93 !important;
  padding: 1rem;
  border: 2px solid #e7eaeb;
  text-align: center;
  transition: 0.5s ease;
  -webkit-animation: flashPW 5s infinite forwards;
          animation: flashPW 5s infinite forwards;
}
.about_home{
  /* width: 90%;
  margin: 0px auto; */
  border: 1px solid rgba(128, 128, 128, 0.171);
  border-radius: 10px;
}
.offering_img{
  width: 200px !important;
  
}
.display_flex{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.offering_img{
  width: 350px !important;
}

@-webkit-keyframes flashPW {
  0% {
    opacity: 1;
    background-color: transparent;
  }

  25% {
    opacity: 0.3;
    background-color: #f5f4ff;
  }

  50% {
    opacity: 1;
    background-color: transparent;
  }

  75% {
    opacity: 0.3;
    background-color: #f5f4ff;
  }

  100% {
    opacity: 1;
    background-color: transparent;
  }
}

@keyframes flashPW {
  0% {
    opacity: 1;
    background-color: transparent;
  }

  25% {
    opacity: 0.3;
    background-color: #f5f4ff;
  }

  50% {
    opacity: 1;
    background-color: transparent;
  }

  75% {
    opacity: 0.3;
    background-color: #f5f4ff;
  }

  100% {
    opacity: 1;
    background-color: transparent;
  }
}

.indications-true {
  height: 165px;
  width: 20%;
  z-index: 1;
  top: 32%;
  position: absolute;
}

.indications-false {
  height: 85px;
  width: 20%;
  z-index: 1;
  top: 32%;
  position: absolute;
}

.dosage-true {
  height: 165px;
  width: 20%;
  z-index: 1;
  bottom: 15%;
  position: absolute;
  left: 5%;
}

.dosage-false {
  height: 85px;
  width: 20%;
  z-index: 1;
  bottom: 15%;
  position: absolute;
  left: 5%;
}

.action-true {
  height: 165px;
  width: 20%;
  z-index: 1;
  top: 32%;
  right: 0;
  position: absolute;
}

.action-false {
  height: 85px;
  width: 20%;
  z-index: 1;
  top: 32%;
  right: 0;
  position: absolute;
}

.FAQ-true {
  height: 165px;
  width: 20%;
  z-index: 1;
  bottom: 15%;
  position: absolute;
  right: 5%;
}

.FAQ-false {
  height: 85px;
  width: 20%;
  z-index: 1;
  bottom: 15%;
  position: absolute;
  right: 5%;
}

.side_effects-true {
  height: 165px;
  width: 20%;
  z-index: 1;
  bottom: 53%;
  position: absolute;
  left: 22%;
}

.side_effects-false {
  height: 85px;
  width: 20%;
  z-index: 1;
  bottom: 53%;
  position: absolute;
  left: 22%;
}

.interaction-true {
  height: 165px;
  width: 20%;
  z-index: 1;
  bottom: 53%;
  position: absolute;
  right: 22%;
}

.interaction-false {
  height: 85px;
  width: 20%;
  z-index: 1;
  bottom: 53%;
  position: absolute;
  right: 22%;
}

.safety_advice-true {
  height: 165px;
  width: 20%;
  z-index: 1;
  top: 8%;
  position: absolute;
  right: 40%;
}

.safety_advice-false {
  height: 85px;
  width: 20%;
  z-index: 1;
  top: 8%;
  position: absolute;
  right: 40%;
}

.select-batch {
  display: grid;
  place-items: center;
}

.select-batch div {
  position: relative;
  display: -webkit-flex;
  display: flex;
  border-radius: 25px;
  width: 250px;
  overflow: hidden;
  color: #ffff;
  box-shadow: 1px 3px 4px 1px #5e5d5d;
}

.select-batch div select {
  -webkit-appearance: none;
          appearance: none;
  outline: 0;
  border: 0;
  border-radius: 25px;
  padding: 0 3rem;
  -webkit-flex: 1 1;
          flex: 1 1;
  background-color: #c82f78;
  color: #ffff;
  cursor: pointer;
  transition: 0.25s ease;
}

.select-batch div select:hover {
  background-color: #e23d7e;
}

.select-batch div::after {
  font-size: 14px;
  content: \25bc;
  position: absolute;
  right: 1rem;
  bottom: 0;
  padding: 1.2rem 2rem;
  transition: 0.25s all ease;
  pointer-events: none;
  color: #ffff;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.batch-img {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  width: 70px !important;
  margin-bottom: 1rem !important;
  cursor: pointer;
}

.batch-img img {
  border-radius: 16px;
  box-shadow: 1px 3px 4px 1px #07070762;
  transition: 0.25s ease;
  -webkit-filter: saturate(2);
          filter: saturate(2);
}

.batch-img img:hover {
  -webkit-filter: sepia(1);
          filter: sepia(1);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: 1px 3px 8px 1px #25252562;
}

.fabButton {
  position: absolute !important;
  z-index: 1 !important;
  top: -34px !important;
  left: 0 !important;
  right: 0 !important;
  margin: 0 auto !important;
  background: white !important;
  border: 1px solid #4b4495 !important;
}
.batch_select{
  font-size: 1.5rem !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  padding-top: 16px !important;
  margin: 0px 10px;
}
.product_name{
  text-align: center;
  font-size: 3rem;
  color: #4B4495;
  font-weight: bolder;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.171);
}
.product_main_data{
  width: 50% !important;
  padding:10px 15px;
  margin:10px 10px;
}
.color_pink{
  color: #E04088 !important;
}
.color_primary{
  color: #4B4495 !important;
}
.about_product{
  padding: 10px;

}
.product_img{
  width: 500px;
 }
.product_section_1{
  -webkit-justify-content: center !important;
          justify-content: center !important;
}

.font_18{
  font-size: 18px;
}
.font_16{
  font-size: 16px;
}
.color_black{
  color: rgb(53, 52, 52) !important;
}
.color_dark{
  color: rgb(14, 13, 13);
}
.faqs{
  padding: 10px 5px;
}
.qlty_rprts{
  margin-left: -10px;
  font-weight: bolder;
  color: #4b4495;
  line-height: 20px;
}
.qlty_rprts:hover{
  color: #E04088;
}
.product_section_2{
  text-align: justify !important;
}
.font_2rem{
  font-size: 2.5rem !important;
}
.text_justify{
  text-align: justify !important;
}
@media only screen and (max-width: 750px){
  .product_main_data{
    width: 100% !important;
  }
}

@media only screen and (max-width: 1001px) and (min-width:751px){
  .product_main_data{
    width: 100% !important;
  }
}
